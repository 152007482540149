import createTsdApi from "../../../client-api/createTsdApi";
import {
  IdCard,
  IdCardCreateParams,
  IdCardQueryParams,
  IdCardUpdateParams,
} from "../../../entities/IdCard/idCard";
import { TsdSearchParam } from "../fetch";

const idCardApi = createTsdApi<
  IdCard,
  IdCardQueryParams,
  IdCardCreateParams,
  IdCardUpdateParams
>("id-cards", (params) => {
  const search: TsdSearchParam<IdCardQueryParams>[] = [];

  ({
    ids: (() => {
      if (typeof params.ids !== "undefined") {
        params.ids.forEach((id) =>
          search.push({ key: "ids", value: String(id) })
        );
      }
    })(),
    limit: (() => {
      if (typeof params.limit !== "undefined") {
        search.push({
          key: "limit",
          value: String(params.limit),
        });
      }
    })(),
    offset: (() => {
      if (typeof params.offset !== "undefined") {
        search.push({
          key: "offset",
          value: String(params.offset),
        });
      }
    })(),
    customerIds: (() => {
      if (typeof params.customerIds !== "undefined") {
        params.customerIds.forEach((id) =>
          search.push({ key: "customerIds", value: String(id) })
        );
      }
    })(),
  }) satisfies {
    [key in keyof Required<IdCardQueryParams>]: void | undefined;
  };

  return search;
});

export default { get: idCardApi.get, getOne: idCardApi.getOne };
