import createTsdApi from "../../../client-api/createTsdApi";
import {
  IdCardTemplate,
  IdCardTemplateCreateParams,
  IdCardTemplateQueryParams,
  IdCardTemplateUpdateParams,
} from "../../../entities/IdCardTemplate/idCardTemplate";
import { TsdSearchParam } from "../fetch";

const idCardTemplateApi = createTsdApi<
  IdCardTemplate,
  IdCardTemplateQueryParams,
  IdCardTemplateCreateParams,
  IdCardTemplateUpdateParams
>("id-card-templates", (params) => {
  const search: TsdSearchParam<IdCardTemplateQueryParams>[] = [];

  ({
    ids: (() => {
      if (typeof params.ids !== "undefined") {
        params.ids.forEach((id) =>
          search.push({ key: "ids", value: String(id) })
        );
      }
    })(),
    limit: (() => {
      if (typeof params.limit !== "undefined") {
        search.push({
          key: "limit",
          value: String(params.limit),
        });
      }
    })(),
    offset: (() => {
      if (typeof params.offset !== "undefined") {
        search.push({
          key: "offset",
          value: String(params.offset),
        });
      }
    })(),
    groupIds: (() => {
      if (typeof params.groupIds !== "undefined") {
        params.groupIds.forEach((id) =>
          search.push({ key: "groupIds", value: String(id) })
        );
      }
    })(),
  }) satisfies {
    [key in keyof Required<IdCardTemplateQueryParams>]: void | undefined;
  };

  return search;
});

export default { get: idCardTemplateApi.get, getOne: idCardTemplateApi.getOne };
