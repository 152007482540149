import createTsdApi from "../../../client-api/createTsdApi";
import {
  RecipeItem,
  RecipeItemCreateParams,
  RecipeItemQueryParams,
  RecipeItemUpdateParams,
} from "../../../entities/RecipeItem/recipeItem";
import { TsdSearchParam } from "../fetch";

const recipeItemApi = createTsdApi<
  RecipeItem,
  RecipeItemQueryParams,
  RecipeItemCreateParams,
  RecipeItemUpdateParams
>("recipe-items", (params) => {
  const search: TsdSearchParam<RecipeItemQueryParams>[] = [];

  ({
    ids: (() => {
      if (typeof params.ids !== "undefined") {
        params.ids.forEach((id) =>
          search.push({ key: "ids", value: String(id) })
        );
      }
    })(),
    productOriginalIds: (() => {
      if (typeof params.productOriginalIds !== "undefined") {
        params.productOriginalIds.forEach((productOriginalId) =>
          search.push({
            key: "productOriginalIds",
            value: String(productOriginalId),
          })
        );
      }
    })(),
    limit: (() => {
      if (typeof params.limit !== "undefined") {
        search.push({
          key: "limit",
          value: String(params.limit),
        });
      }
    })(),
    offset: (() => {
      if (typeof params.offset !== "undefined") {
        search.push({
          key: "offset",
          value: String(params.offset),
        });
      }
    })(),
  }) satisfies {
    [key in keyof Required<RecipeItemQueryParams>]: void | undefined;
  };

  return search;
});

export default {
  get: recipeItemApi.get,
  getOne: recipeItemApi.getOne,
  update: recipeItemApi.update,
};
