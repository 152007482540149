import createTsdApi from "../../../client-api/createTsdApi";
import {
  PrintQueue,
  PrintQueueCreateParams,
  PrintQueueQueryParams,
  PrintQueueUpdateParams,
} from "../../../entities/PrintQueue/printQueue";
import tsdFetch, { TsdSearchParam } from "../fetch";

const printQueueApi = createTsdApi<
  PrintQueue,
  PrintQueueQueryParams,
  PrintQueueCreateParams,
  PrintQueueUpdateParams
>("print-queue", (params) => {
  const search: TsdSearchParam<PrintQueueQueryParams>[] = [];
  ({
    limit: (() => {
      if (typeof params.limit !== "undefined") {
        search.push({ key: "limit", value: String(params.limit) });
      }
    })(),
    offset: (() => {
      if (typeof params.offset !== "undefined") {
        search.push({ key: "offset", value: String(params.offset) });
      }
    })(),
    isPrinted: (() => {
      if (typeof params.isPrinted !== "undefined") {
        search.push({ key: "isPrinted", value: params.isPrinted ? "1" : "0" });
      }
    })(),
    type: (() => {
      if (typeof params.type !== "undefined") {
        search.push({ key: "type", value: params.type });
      }
    })(),
    createdLte: (() => {
      if (typeof params.createdLte !== "undefined") {
        search.push({ key: "createdLte", value: params.createdLte });
      }
    })(),
  }) satisfies {
    [key in keyof Required<PrintQueueQueryParams>]: void | undefined;
  };

  return search;
});

const create = (fields: PrintQueueCreateParams & { file: Blob }) => {
  const formData = new FormData();

  formData.append("file", fields.file);
  formData.append("type", fields.type);

  if (typeof fields.jobId !== "undefined") {
    formData.append("jobId", String(fields.jobId));
  }

  return tsdFetch(`/api/v1/print-queue`, {
    method: "POST",
    body: formData,
  });
};

export default { ...printQueueApi, create };
