import createTsdApi from "../../../client-api/createTsdApi";
import {
  InternalTaskComment,
  InternalTaskCommentCreateFields,
  InternalTaskCommentQueryParams,
  InternalTaskCommentUpdateFields,
} from "../../../entities/InternalTaskComment/internalTaskComment";
import { TsdSearchParam } from "../fetch";

const internalTaskCommentApi = createTsdApi<
  InternalTaskComment,
  InternalTaskCommentQueryParams,
  InternalTaskCommentCreateFields,
  InternalTaskCommentUpdateFields
>("internal-task-comments", (params) => {
  const searchParams: TsdSearchParam<InternalTaskCommentQueryParams>[] = [];

  ({
    internalTaskOriginalIds: (() => {
      if (typeof params.internalTaskOriginalIds !== "undefined") {
        params.internalTaskOriginalIds.forEach((id) => {
          searchParams.push({
            key: "internalTaskOriginalIds",
            value: String(id),
          });
        });
      }
    })(),
    isDeleted: (() => {
      if (typeof params.isDeleted !== "undefined") {
        searchParams.push({
          key: "isDeleted",
          value: params.isDeleted ? "1" : "0",
        });
      }
    })(),
    limit: (() => {
      if (typeof params.limit !== "undefined") {
        searchParams.push({
          key: "limit",
          value: String(params.limit),
        });
      }
    })(),
    offset: (() => {
      if (typeof params.offset !== "undefined") {
        searchParams.push({
          key: "offset",
          value: String(params.offset),
        });
      }
    })(),
    orderById: (() => {
      if (typeof params.orderById !== "undefined") {
        searchParams.push({
          key: "orderById",
          value: params.orderById,
        });
      }
    })(),
    orderByCreated: (() => {
      if (typeof params.orderByCreated !== "undefined") {
        searchParams.push({
          key: "orderByCreated",
          value: params.orderByCreated,
        });
      }
    })(),
  }) satisfies {
    [key in keyof Required<InternalTaskCommentQueryParams>]: void | undefined;
  };

  return searchParams;
});

export default internalTaskCommentApi;
